<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
		
		<div class="BGWhite" v-if="CartList == null">
			<div style="line-height: 400px;text-align: center;">
				购物车信息载入中
			</div>
		</div>
		<div class="BGWhite" v-if="CartList != null && CartList.length == 0">
			<div style="line-height: 400px;text-align: center;">
				当前没有购物车商品信息
			</div>
		</div>
		
<div class="BGWhite" style="border-bottom: 20px solid #ffffff;" v-if="CartList != null && CartList.length > 0">
	<div class="Wrap">
		
		
		<div style="width: 100%;margin-bottom: 10px;" v-for="(shopCart,shopCartI) in CartList" :key="shopCartI">
			<div class="GoodsList">
				<div class="Hi Title" style="color: rgba(0,0,0,0.4);font-size:0.9rem">
					
					<span class="C2">商品图</span>
					<span class="C3">店铺:{{shopCart.Name}}</span>
					<span class="C5">单价</span>
					<span class="C6">数量</span>
					<span  class="C7">小计</span>
					<span class="C8">操作</span>
				</div>
			</div>

			<div class="GoodsList">
				<div class="Hi Cart" v-for="item in shopCart.GoodsList" :key="item.Id">

					<span class="C2"><img :src="item.GoodsThumb" @click="$Jump('/goods/detail/'+item.GoodsId)" /></span>
					<span class="C3">
						<div>
							<p>
							{{item.GoodsName}}</p>
							<em v-if="item.SkuName.length > 0">规格:{{item.SkuName}}</em>
						</div>
					</span>
					<span class="C5">￥{{item.GoodsPrice}}</span>
					<div class="C6">
						<el-input-number @change="ChangeNum(item)" style="width: 100px;" v-model="item.Num" size="mini" :min="1"></el-input-number>
					</div>
					<span class="C7">￥{{(item.GoodsPrice*item.Num).toFixed(2)}}</span>
					<span class="C8">
						<em><label @click="DelId(item.Id)">删除</label></em>
					</span>
				</div>
			</div>
				
		</div>
		
		
		<div class="Count">
			<div class="Info">
				
				<div>
					已选商品 <i>{{CountSels}}</i> 件
					<em class="Sc">|</em>
					总价:
					<label>￥{{CountMoney}}</label>
				</div>
			</div>
			<div class="Act" @click="OrderCheck()">
				去结算
			</div>
		</div>
		
	</div>
</div>
<Footer></Footer>
</div>
</template>

<script>
	import {InputNumber,Checkbox,Pagination} from 'element-ui'
	export default {
	  name: 'ShoppingCart',
	  props: {
	  },
	  data() {
	      return {
			  checkList:[],
			  CartList:[], //购物车清单
			  OriginCartList:[],//原始购物车信息，用来比较是否存在商品数量改变
			  Page:1,//当前页码
			  PageSize:10,//单页条数
			  Total:0,//总条数
			  SelecteAll:false,
			  CountSels:0,
			  CountMoney:0,
			  CouponList:[],//我的优惠券清单
	      }
	    },
	  components: {
		  "el-input-number":InputNumber,
		    "el-checkbox":Checkbox,
			 'el-pagination':Pagination,
	  },
	  watch:{
		  SelecteAll(v){
			  this.SelecteAllChange(v)
		  },
		  CartList:{
			handler(n,o){
				this.SumMoney()
			},
			deep: true
		  }
	  },
	  created() {
	  	if(this.$store.getters.getToken == ''){
	  		this.$Jump('/login')
	  	}
		this.$router.afterEach((to,from,next) => {
		  window.scrollTo(0,0);
		});
		this.GetCartList()
	  },
	  methods:{
		  SumMoney(){
			  
			  if(!this.CartList){
				  return
			  }
			  
			  this.CountSels = 0
			  this.CountMoney = 0
			  
			  this.CartList.forEach(shopCart => {
				for(var i = 0;i<shopCart.GoodsList.length;i++){
					if(shopCart.GoodsList[i].IsSelected){
						this.CountSels += 1
						this.CountMoney += shopCart.GoodsList[i].GoodsPrice*shopCart.GoodsList[i].Num
					}
				}
				
			  })
			 
			  this.CountMoney = this.CountMoney.toFixed(2)
		  },
		  GetCartList(){
			  
			  this.CartList = []
			  this.OriginCartList = []
			  //发起网络请求，添加购物清单
			  let data = {
			  	Service:'Goods',
			  	Class: 'ShoppingCart',
			  	Action: 'List',
				IsPt:2,
			  	Page:this.Page,
			  	PageSize:this.PageSize,
			  }
			  
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
				this.GetCartCount()
				
				if(res.Data.CartList == null){
					return
				}
			  	this.CartList = res.Data.CartList
				this.OriginCartList = res.Data.CartList
				//构建全选信息
				this.CartList.forEach(shopCart => {
					for(var i = 0;i<shopCart["GoodsList"].length;i++){
						shopCart["GoodsList"][i].IsSelected = true
					}
					
				})
				
				
			  	
			  })
		  },
		  GetCartCount(){
		  			  
			  //发起网络请求，添加购物清单
			  let data = {
				Service:'Goods',
				Class: 'ShoppingCart',
				IsPt:2,
				Action: 'Count',
			  }
			  
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				if(res.Data.Count > 0){
					this.$store.dispatch('setCartItems',res.Data.Count)
				}else{
					this.$store.dispatch('setCartItems',0)
				}
				
				this.Total = res.Data.Count
				
			  })
		  },
		  DelId(_id){
			  let ids = [_id]
			  
				this.$confirm('此操作将删除购物信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				  }).then(() => {
					this.DelCarts(ids)
				  })
					  
		  },
		  DelIds(){
			let ids = []
			this.CartList.forEach(shopCart => {
				for(var i=0;i<shopCart.GoodsList.length;i++){
					if(shopCart.GoodsList[i].IsSelected){
						ids.push(shopCart.GoodsList[i].Id)
					}
				}
			})
			if(ids.length == 0){
				this.$message('没有选中商品可供删除')
				return
			}
			 
			 this.$confirm('此操作将删除购物信息, 是否继续?', '提示', {
			 	confirmButtonText: '确定',
			 	cancelButtonText: '取消',
			 	type: 'warning'
			   }).then(() => {
			 	this.DelCarts(ids)
			   })
			   
		  },
		  DelCarts(_ids){//发起网络请求，添加购物清单
			
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'ShoppingCart',
		  			  	Action: 'DelIds',
		  			  	Ids:JSON.stringify(_ids)
		  			  }
		  			  
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	
						this.GetCartList()
		  			  	
		  			  })
		  },
		  ChangeNum(_cart){
			  this.NumChange(_cart.Id,_cart.Num)
		  },
		  NumChange(_cartId,_num){
			  
			  //修改服务器中该购物车商品的数量
			  let data = {
			  	Service:'Goods',
			  	Class: 'ShoppingCart',
			  	Action: 'Update',
			  	Id:_cartId,
				Num:_num
			  }
			  
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
			  })
		  },
		  OrderCheck(){
			  
			  if(this.CountSels == 0){
				  this.$message('请至少选择一件商品')
				  return
			  }
			  
			  let ids = ''
			  
			  this.CartList.forEach(shopCart => {
				for(var i=1;i<=shopCart.GoodsList.length;i++){
				  if(shopCart.GoodsList[i-1].IsSelected){
					  ids += shopCart.GoodsList[i-1].Id + ','
				  }
				}  
			  })
			  ids = ids.substring(0,ids.length-1)
			 
			  this.$Jump('/buy/'+ids)
			  console.log('下单确认')
		  },
		  //下单
		  
	  }
	}
</script>

<style scoped>
	
	.GoodsList{
		width: 100%;
	}
	.GoodsList .Hi{
		display: flex;
		width: 100%;
		padding: 15px 0px;
		border: 1px solid rgba(0,0,0,0.05);
		border-top: 0px;
		align-items: center;
	}
	.GoodsList .Hi:hover{
		background-color: rgba(228,0,0,0.04);
	}
	.GoodsList .Title{
		background-color: rgba(0,0,0,0.05);
	}
	.GoodsList .Hi span.CartSpan{
		text-align: center;
	}
	.GoodsList .C1{
		width: 60px;
		padding-left: 10px;
	}
	.GoodsList .C2{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C2 img{
		width: 80px;
	}
	.GoodsList .C3{
		flex: 1;
		padding-left: 10px;
	}
	.GoodsList .C3 p{
		margin-top: 0px;
		padding-right: 10px;
		color: #888;
		margin-bottom: 3px;
	}
	.GoodsList .C3 p i{
		font-style: normal;
		color: rgb(228,0,0,1);
	}
	.GoodsList .C3 em{
		color: rgba(0,0,0,0.3);
	}
	.GoodsList .C4,.GoodsList .C5,.GoodsList .C6,.GoodsList .C7{
		width: 120px;
		padding-left: 10px;
	}
	.GoodsList .C8{
		width: 120px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.C8 em{
		display: block;
		width: 100%;
	}
	.C8 em label{
		cursor: pointer;
	}
	.Count{
		margin-top: 20px;
		display: flex;
		width: 100%;
	}
	.Count .Info{
		flex: 1;
		border: 1px solid rgba(0,0,0,0.04);
		border-right-width: 0px;
		display: flex;
		padding: 10px;
		align-items: center;
	}
	.Count .Info span.Sc{
		margin-left: 15px;
		cursor: pointer;
	}
	.Count .Info div{
		flex: 1;
		text-align: right;
	}
	.Count .Info div em.Sc{
		margin-left: 10px;
		margin-right: 10px;
	}
	.Count .Info div i.Sc{
		color: rgba(228,0,0,1);
		font-style: normal;
	}
	.Count .Info div label.Sc{
		font-size: 1.6rem;
		color: rgba(228,0,0,1);
	}
	.Count .Act{
		background-color: rgba(228,0,0,1);
		color: #FFFFFF;
		width: 200px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
</style>
